import React from 'react';
import styled from 'styled-components';
import { INode } from '../types/INode';
import { ILocalFileTheme } from '../types/ITheme';
import NewsLetterCTA from './NewsLetterCTA';
import ThemeCard from './ThemeCard';

const ThemeCardListStyles = styled.div`
  .theme-title {
    margin-bottom: 1rem;
  }

  .theme-introduction {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.color.secondary};
    p {
      min-width: 15rem;
      font-size: clamp(${({ theme }) => theme.font.size.base}, 0.9rem + 1vw, ${({ theme }) => theme.font.size.basePlus});
      font-weight: ${({ theme }) => theme.font.weight.extraLight};
      color: ${({ theme }) => theme.color.secondary};
      margin-bottom: 0.2rem;
    }
  }

  @media ${({ theme }) => theme.media.query.tablet} {
    .theme-introduction {
      p {
      }
    }
  }
`;

const CardsContainer = styled.div`
  --min-card-size: 16rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-card-size, 15rem), 1fr));
  column-gap: 2rem;
  row-gap: 3rem;
  justify-content: center;

  padding-bottom: 2rem;
  margin-bottom: 2rem;
  /* border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.color.secondary}; */
`;

type propTypes = {
  themes: INode<ILocalFileTheme>[];
};

export default function ThemeCardList({ themes }: propTypes): JSX.Element {
  return (
    <ThemeCardListStyles>
      <h1 className="page-title theme-title">Éditions: Simplissi-mots</h1>
      <div className="theme-introduction">
        <p>Maison d’édition d’Elisabeth Pastor</p>
      </div>
      <CardsContainer>
        {themes.map(({ node: theme }) => {
          return (
            <div key={theme.id}>
              <ThemeCard theme={theme}></ThemeCard>
            </div>
          );
        })}
      </CardsContainer>
      {/* <NewsLetterCTA></NewsLetterCTA> */}
    </ThemeCardListStyles>
  );
}
