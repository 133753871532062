import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { ILocalFileTheme } from '../types/ITheme';
import ArrowRight from '../assets/svg/ArrowRight.svg';

const ThemeCardStyles = styled.article`
  transition: box-shadow ${({ theme }) => theme.transitionTiming.default} ease;
  box-shadow: 0 1px 2px rgba(${({ theme }) => theme.color.border}, 0.1), 0 4px 8px rgba(${({ theme }) => theme.color.border}, 0.2);
  border-radius: ${({ theme }) => theme.radius.default};
  height: 100%;

  .img-card__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
    color: ${({ theme }) => theme.color.primary};
    overflow: hidden;
    border-radius: ${({ theme }) => theme.radius.default};
  }

  .img-card__img-wrapper {
    transition: transform ${({ theme }) => theme.transitionTiming.default} ease-in;
  }

  .img-card__img {
    border-radius: ${({ theme }) => theme.radius.default} ${({ theme }) => theme.radius.default} 0 0;
    width: 100%;
  }

  .img-card__inner {
    padding: ${({ theme }) => theme.spacing['inner-card']} ${({ theme }) => theme.spacing['inner-card']} 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    z-index: 10;
    background: ${({ theme }) => theme.color.bg};
  }

  .img-card__title {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .img-card__description {
    flex: 1;
    margin-bottom: 1.25rem;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  .img-card__redirect {
    margin-bottom: 1.25rem;
  }

  &:hover {
    box-shadow: 0 1px 2px 0 rgba(${({ theme }) => theme.color.border}, 0.15), 0 4px 15px rgba(${({ theme }) => theme.color.border}, 0.3);

    .img-card__img-wrapper {
      transform: scale(1.05);
    }

    .link {
      text-decoration: underline;

      svg {
        transform: translateX(5px);
      }
    }
  }

  img[data-placeholder-image] {
    filter: blur(20px);
  }
`;

export default function ThemeCard({ theme }: { theme: ILocalFileTheme }) {
  return (
    <ThemeCardStyles>
      <Link className="img-card__container" to={`/theme/${theme.parent.slug.current}`}>
        <div className="img-card__img-wrapper">
          <GatsbyImage imgClassName="img-card__img" image={theme.childImageSharp.gatsbyImageData} alt={theme.parent.title}></GatsbyImage>
        </div>
        <div className="img-card__inner">
          <h1 className="img-card__title">{theme.parent.title}</h1>
          <p className="img-card__description">{theme.parent.description}</p>
          <p className="img-card__redirect link">
            Découvrir ce thème
            <ArrowRight viewBox="0 0 140 140"></ArrowRight>
          </p>
        </div>
      </Link>
    </ThemeCardStyles>
  );
}
