import React, { ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';
import ThemeCardList from '../components/ThemeCardList';
import { ILocalFileTheme } from '../types/ITheme';
import { IPageQuery } from '../types/IPageQuery';
import SEO from '../components/SEO';

// These styles need to be reimported on each page.tsx file otherwise the page won't have them
import '../assets/style/utilities/wrapper.css';
import '../assets/style/font.css';

export default function IndexPage(props: PageProps<IPageQuery<ILocalFileTheme>>): ReactNode {
  const sortedThemes = props.data.themesWithLocalImg.edges.sort((a, b) => {
    return a.node.parent.title.localeCompare(b.node.parent.title, 'fr', { ignorePunctuation: true });
  });

  return (
    <>
      <SEO />
      <ThemeCardList themes={sortedThemes}></ThemeCardList>
    </>
  );
}

// https://www.gatsbyjs.com/plugins/gatsby-plugin-image
export const query = graphql`
  query allTheme {
    themesWithLocalImg: allFile(filter: { parent: { internal: { type: { eq: "SanityTheme" } } } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(aspectRatio: 1.3, placeholder: BLURRED, layout: FULL_WIDTH)
          }
          parent {
            id
            ... on SanityTheme {
              id
              title
              description
              slug {
                current
              }
              internal {
                type
              }
            }
          }
        }
      }
    }
  }
`;
